
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/cards/Overview.vue";
import CustomCard from "@/view/pages/resources/documentation/base/cards/CustomCard.vue";
import CardScroll from "@/view/pages/resources/documentation/base/cards/CardScroll.vue";
import FlushBorders from "@/view/pages/resources/documentation/base/cards/FlushBorders.vue";
import ResetSidePaddings from "@/view/pages/resources/documentation/base/cards/ResetSidePaddings.vue";
import CardBorder from "@/view/pages/resources/documentation/base/cards/CardBorder.vue";
import CardScretch from "@/view/pages/resources/documentation/base/cards/CardStretch.vue";
import UtilityClasses from "@/view/pages/resources/documentation/base/cards/UtilityClasses.vue";

export default defineComponent({
  name: "cards",
  components: {
    Overview,
    CustomCard,
    CardScroll,
    FlushBorders,
    ResetSidePaddings,
    CardBorder,
    CardScretch,
    UtilityClasses,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Cards");
    });
  },
});
